import { Link } from 'gatsby'
import React from 'react'
import GlobalStateContext from '../context/globalStateContext'
import { useAuth0 } from '../context/react-auth0-spa'

export default function OtherToolLink({ tool }) {
  const { user } = useAuth0()
  const { station } = React.useContext(GlobalStateContext)
  const [activeTools, setActiveTools] = React.useState([])

  React.useEffect(() => {
    if (!user) return

    if (Object.keys(user).includes('activeTools')) {
      setActiveTools(
        user.activeTools
          .filter((t) => t.tool === tool)
          .filter((t) => t.isVisible)
          .sort((a, b) => b.updatedAt - a.updatedAt),
      )
    }
  }, [user, tool])

  return (
    <div className="lg:col-span-4">
      <h2 className="mb-3 text-lg font-bold text-left font-body bg-gray-50 py-2 -ml-1 pl-1 rounded-md">
        {tool}
      </h2>
      {activeTools.length === 0 ? (
        <Link
          to="/user/profile"
          state={{ tab: 3 }}
          className="flex items-center py-2 my-1"
        >
          Select from Profile
        </Link>
      ) : (
        <ul className="flex flex-col mb-4 lg:mb-0 text-left">
          {activeTools.map((d) => {
            let link = d.link
            if (station) {
              if (d.name === 'NWS Forecasts') {
                link = `https://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`
              }
              if (d.name === 'NWS Hourly Weather Forecast Graph') {
                link = `https://forecast.weather.gov/MapClick.php?lat=${station.lat}&lon=${station.lon}&unit=0&lg=english&FcstType=graphical`
              }
            }
            return (
              <li key={d.id} className="flex items-center py-2 my-1">
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-md"
                >
                  <span className="text-left">{d.name}</span>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

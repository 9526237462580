import React from "react"
import GlobalStateContext from "../../context/globalStateContext"
import { modelInSeason } from "../../utils/helpers"
import modelLogic from "./_modelLogic"
import modelData from "./sooty-blotch-fly-speck.json"

export default function DashboardSummarySootyBlotchFlyspeck({ data }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const isModelInSeason = modelInSeason(dateOfInterest, modelData)

  let mData = null
  if (data && isModelInSeason) {
    mData = modelLogic(modelData, data, dateOfInterest)
  }

  if (!mData) return null

  const currentDate = mData.dashboardSummary.currentDate

  return (
    <div className="grid grid-cols-1 px-2 py-3 text-gray-700 rounded sm:grid-cols-2 gap-x-2 bg-gray-50">
      <div className="flex items-center py-2 font-extrabold">
        {currentDate && currentDate.degreeDaysToPetalFall >= 0 ? (
          <p className="p-2 mx-auto mb-2 text-gray-700 rounded-md bg-gray-50">
            You are approximately{" "}
            <span className="font-extrabold">
              {currentDate.degreeDaysToPetalFall}
            </span>{" "}
            from petal fall - the point at which apples and pears become
            susceptible to infection. Sooty Blotch and Fly Speck predictions
            begin at petal fall.
          </p>
        ) : (
          <p className="p-2 mb-2 text-gray-700 rounded-md bg-gray-50">
            <span className="pr-2 font-normal">Risk Level:</span>
            <span className="font-extrabold">
              {currentDate && currentDate.riskLevel}
            </span>
          </p>
        )}
      </div>
      {isModelInSeason && (
        <div className="flex items-center sm:justify-end">
          <div>
            {/* Petal Fall */}
            <div className="py-1">
              <span className="pr-2 font-normal">Days Since Petal Fall:</span>
              <span className="font-extrabold">
                {currentDate && currentDate.daysSincePetalFall}
              </span>
            </div>

            <div className="py-1">
              <span className="pr-2 font-normal">
                Accumulated Leaf Wetness Hours Since Petal Fall:
              </span>
              <span className="font-extrabold">
                {currentDate &&
                  currentDate.accumulatedLeafWetnessHoursAboveOneMinute}
              </span>
            </div>

            {/* Fungicide application date */}
            {mData.biofix2 && (
              <div className="py-1">
                <span className="pr-2 font-normal">
                  Days Since Last Fungicide Application:
                </span>
                <span className="font-extrabold">
                  {currentDate && currentDate.daysSinceFungicide}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

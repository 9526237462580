import { gddRangeToMsg, calculateGdd, simpleAve } from "../../utils/helpers"
import { format } from "date-fns"

export default function AlfalfaWeevilLogic(modelData, data, dateOfInterest) {
  const { elements, base } = modelData

  const dailyDataWithGdd = calculateGdd(data.dailyData, base, 0, simpleAve).map(
    (d) => {
      return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
    }
  )

  // Determine Management Guide Message ------------------------------------------------
  let selectedDate = dailyDataWithGdd.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = dailyDataWithGdd.find((d) => d.isForecast)
  }

  let managementGuideMsg = null
  if (selectedDate) {
    managementGuideMsg = gddRangeToMsg(
      elements.managementGuide.thresholds,
      selectedDate.gdd
    )
  }
  // ///////////////////////////////////////////////////////////////////////////////////

  // Dashboard summary ----------------------------------------------------------------
  let dashboard = { managementGuide: { dashboardDisplay: "" }, fromJan1: "" }

  const lastDate = dailyDataWithGdd.find((d) => d.isForecast)
  if (lastDate) {
    const msg = gddRangeToMsg(elements.managementGuide.thresholds, lastDate.gdd)
    if (msg) {
      dashboard.managementGuide = msg
      dashboard.fromJan1 = lastDate.gdd
    }
  }

  return {
    data: dailyDataWithGdd,
    managementGuideMsg,
    dashboard,
  }
}

import wso from '../../static/weather-station-overview.json'
import { useAuth0 } from '../context/react-auth0-spa'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import VisuallyHidden from '@reach/visually-hidden'
import React from 'react'

export default function Modal({ title, isModalOpen, setIsModalOpen, station }) {
  const close = () => setIsModalOpen(false)
  const labelId = `label--${Date.now()}`
  const { baseTemperature, formula } = wso.customDegreeDay
  const { isAuthenticated, user, setUser } = useAuth0()
  const [weatherVariables, setWeatherVariables] = React.useState([])
  const [customDD, setCustomDD] = React.useState(wso.customDegreeDay)

  React.useEffect(() => {
    if (!isAuthenticated && station === null) return

    let stationParams = []
    const elems = Object.keys(station.elems)
    let extra = []
    if (station.extraelems.length !== 0) {
      extra = station.extraelems.map((d) => d.name)
    }
    stationParams = [...elems, ...extra]

    const stationWeatherVariables = wso.weatherVariables.filter((w) =>
      stationParams.includes(w.param),
    )
    if (Object.keys(user).includes('weatherVariables')) {
      const userParams = user.weatherVariables
        .map((d) => d.param)
        .filter((d) => stationParams.includes(d))

      setWeatherVariables(
        stationWeatherVariables.map((d) => {
          if (userParams.includes(d.param)) {
            d.isChecked = true
            return d
          } else {
            d.isChecked = false
            return d
          }
        }),
      )
    } else {
      setWeatherVariables(stationWeatherVariables)
    }
    if (Object.keys(user).includes('customDegreeDay')) {
      let customDegreeDayCopy = user.customDegreeDay
      const currentMonth = new Date().getMonth() + 1
      let options = ['January 1']
      if (currentMonth >= 3) options = ['January 1', 'March 1']
      if (currentMonth >= 4) options = ['January 1', 'March 1', 'April 1']
      if (currentMonth >= 5)
        options = ['January 1', 'March 1', 'April 1', 'May 1']

      customDegreeDayCopy.accumulationStartDate.options = options
      setCustomDD(customDegreeDayCopy)
    }
  }, [isAuthenticated, station, user])

  function toggleWeatherVariables({ target }) {
    const weatherVariablesCopy = [...weatherVariables]
    const idx = weatherVariablesCopy.findIndex((d) => d.name === target.name)
    weatherVariablesCopy[idx]['isChecked'] =
      !weatherVariablesCopy[idx]['isChecked']
    setWeatherVariables(weatherVariablesCopy)
  }

  function toggleCustomDegreeDay() {
    const customDegreeDayCopy = { ...customDD }
    customDegreeDayCopy['isChecked'] = !customDegreeDayCopy['isChecked']
    setCustomDD(customDegreeDayCopy)
  }

  function handleCustomDegreeDay({ target }) {
    const customDegreeDayCopy = { ...customDD }
    customDegreeDayCopy[target.name].value = target.value
    setCustomDD(customDegreeDayCopy)
  }

  return (
    <div className="">
      {isModalOpen && (
        <Dialog
          isOpen={isModalOpen}
          onDismiss={close}
          aria-labelledby={labelId}
          className="max-w-lg rounded-lg"
        >
          <button className="text-3xl close-button" onClick={close}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </button>
          <div className="">
            <div className="text-center">
              <h3
                className="text-lg font-medium leading-6 text-gray-900"
                id="modal-headline"
              >
                {title}
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500">
                  Select what data display for{' '}
                  <span className="font-bold">
                    {station.name}, {station.state}
                  </span>
                  . Click or tap the box next to the variable to switch it ON
                  (green box with check mark) or OFF (open box).
                </p>
              </div>
            </div>

            <div className="block mt-8 ml-2 text-xs leading-5 text-gray-900 lg:text-sm">
              Custom Degree Day (select an additional DD)
            </div>
            <div className="flex items-center p-1 bg-gray-50 ">
              <input
                id="customDegreeDay"
                type="checkbox"
                aria-checked={customDD.isChecked}
                name="customDegreeDay"
                onChange={toggleCustomDegreeDay}
                checked={customDD.isChecked}
                className={`form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out mr-2 `}
              />
              <label
                htmlFor="customDegreeDay"
                className="block ml-2 text-sm leading-5 text-gray-900 "
              >
                <div
                  className={`flex flex-col ${
                    customDD.isChecked ? `opacity-100` : 'opacity-70'
                  }`}
                >
                  <div
                    className={`flex flex-col lg:flex-row lg:items-center mt-1 text-sm`}
                  >
                    <label
                      htmlFor="formula"
                      className="mr-4 text-xs lg:flex lg:whitespace-nowrap lg:text-sm"
                    >
                      Degree day formula
                    </label>
                    <select
                      name="formula"
                      id="formula"
                      value={customDD.formula.value}
                      disabled={customDD.isChecked ? false : true}
                      onChange={handleCustomDegreeDay}
                      onBlur={handleCustomDegreeDay}
                      className="py-2 mt-1 text-xs border-gray-300 rounded-md lg:text-base lg:pr-10 lg:pl-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    >
                      {formula.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className={`mt-2 lg:mt-1 flex flex-col lg:flex-row lg:items-center text-sm`}
                  >
                    <label
                      htmlFor="baseTemperature"
                      className="mr-4 text-xs lg:flex lg:whitespace-nowrap lg:text-sm"
                    >
                      Base
                    </label>
                    <select
                      name="baseTemperature"
                      id="baseTemperature"
                      value={customDD.baseTemperature.value}
                      disabled={customDD.isChecked ? false : true}
                      onChange={handleCustomDegreeDay}
                      onBlur={handleCustomDegreeDay}
                      className="py-2 mt-1 text-xs border-gray-300 rounded-md lg:text-base lg:pr-10 lg:pl-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    >
                      {baseTemperature.options.map((option) => {
                        if (
                          formula === 'Baskerville Emin' &&
                          option === '86/50˚F'
                        ) {
                          return null
                        } else {
                          return (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          )
                        }
                      })}
                    </select>
                  </div>
                  <div
                    className={`mt-2 lg:mt-1  flex flex-col lg:flex-row lg:items-center text-sm`}
                  >
                    <label
                      htmlFor="accumulationStartDate"
                      className="mr-4 text-xs lg:flex lg:whitespace-nowrap lg:text-sm"
                    >
                      Degree Days Since
                    </label>
                    <select
                      name="accumulationStartDate"
                      id="accumulationStartDate"
                      value={customDD.accumulationStartDate.value}
                      disabled={customDD.isChecked ? false : true}
                      onChange={handleCustomDegreeDay}
                      onBlur={handleCustomDegreeDay}
                      className="py-2 mt-1 text-xs border-gray-300 rounded-md lg:text-base lg:pr-10 lg:pl-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    >
                      {customDD.accumulationStartDate.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {weatherVariables ? (
              <ul className="mt-8">
                {weatherVariables.map((d) => {
                  return (
                    <div
                      key={d.id}
                      className={`flex justify-between items-center bg-gray-50 my-2 p-1 ${
                        d.isChecked ? `opacity-100` : `opacity-70`
                      }`}
                    >
                      <span className="flex items-center">
                        <input
                          id={d.id}
                          type="checkbox"
                          aria-checked={d.isChecked}
                          name={d.name}
                          onChange={toggleWeatherVariables}
                          checked={d.isChecked}
                          className="w-4 h-4 transition duration-150 ease-in-out form-checkbox text-primary-600"
                        />
                        <label
                          htmlFor={d.name}
                          className="block ml-2 text-xs leading-5 text-gray-900 lg:text-sm"
                        >
                          <span className="mt-1 mr-auto tracking-wide">
                            {d.name}
                          </span>
                        </label>
                      </span>

                      <span className="text-xs italic text-gray-500 lg:text-sm">
                        {d.unit}
                      </span>
                    </div>
                  )
                })}
              </ul>
            ) : (
              <div className="mt-3 text-center sm:mt-5">Loading...</div>
            )}
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              onClick={() => {
                setUser({
                  ...user,
                  weatherVariables: weatherVariables.filter((d) => d.isChecked),
                  customDegreeDay: customDD,
                })
                setIsModalOpen(false)
              }}
              className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Save
            </button>
          </div>
        </Dialog>
      )}
    </div>
  )
}

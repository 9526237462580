import GlobalStateContext from "../../context/globalStateContext"
import { modelInSeason } from "../../utils/helpers"
import modelLogic from "./_modelLogic"
import modelData from "./blueberry-maggot.json"
import React from "react"

export default function DashboardSummaryBlueberryMaggot({ data }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const isModelInSeason = modelInSeason(dateOfInterest, modelData)

  let mData
  if (data) {
    mData = modelLogic(data, dateOfInterest, modelData, isModelInSeason)
  }

  if (!mData) return null
  return (
    <div className="grid grid-cols-1 px-2 py-3 text-gray-700 rounded sm:grid-cols-2 gap-x-2 bg-gray-50">
      <div className="flex items-center py-2 font-extrabold">
        <span>{mData.dashboard.managementGuide.dashboardDisplay}</span>
      </div>
      {isModelInSeason && (
        <div className="flex items-center sm:justify-end">
          <div>
            <div className="py-1">
              <span className="pr-2 font-normal">
                {modelData.dashboardDisplay} since Jan 1:
              </span>
              <span className="font-extrabold">{mData.dashboard.fromJan1}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import React from 'react'
import newaCropAndIpmTools from '../../static/newa-crop-ipm-tools.json'
import AccordionRow from '../components/accordionRow'

let newaTools = {}
newaCropAndIpmTools.forEach((d) => {
  if (d.tool in newaTools) {
    newaTools[d.tool].push(d)
  } else {
    newaTools[d.tool] = [d]
  }
})

export default function NewaTools({ user }) {
  const activeTools = Object.keys(user).includes('activeTools')
    ? user.activeTools.filter((t) => t.isVisible)
    : []

  const stateOrProvince = Object.keys(user).includes('stateOrProvince')
    ? user.stateOrProvince
    : 'NY'

  let defaultIndices = []
  const tools = activeTools.map((t) => t.tool)
  if (tools.includes('Apple Tools')) defaultIndices.push(0)
  if (tools.includes('Berry Tools')) defaultIndices.push(1)
  if (tools.includes('Grape Tools')) defaultIndices.push(2)
  if (tools.includes('Field Crop Tools')) defaultIndices.push(3)
  if (tools.includes('Vegetable Tools')) defaultIndices.push(4)

  const [accordionIndices, setAccordionIndices] = React.useState(defaultIndices)

  function toggleAccordion(toggledIndex) {
    if (accordionIndices.includes(toggledIndex)) {
      setAccordionIndices(
        accordionIndices.filter(
          (currentIndex) => currentIndex !== toggledIndex,
        ),
      )
    } else {
      setAccordionIndices([...accordionIndices, toggledIndex].sort())
    }
  }

  return (
    <section className="mx-4 mt-3 sm:mx-1">
      <h2 className="text-2xl font-semibold text-center lg:text-3xl">
        Your NEWA Crop & IPM Tools
      </h2>
      <p className="max-w-2xl mx-auto text-center md:text-lg">
        Choose or edit the NEWA tools you want. Open the crop’s tool box by
        clicking or tapping the arrow. Switch the tools to 'On' or 'Off' by
        clicking or tapping the slider button. Tool results will display on your
        Dashboard for your favorite stations. Choices and edits are saved
        immediately.
      </p>
      <p className="max-w-2xl mx-auto text-center">
        NOTE: When accessing NEWA Tools from the Crop & IPM Tools landing page,
        biofix dates won’t be saved, unless the NEWA Tool is already included on
        your Profile.
      </p>
      <p className="max-w-2xl mx-auto text-sm text-center">
        Next, click or tap on Other Tools or go to your Dashboard.{' '}
      </p>

      <div className="py-6">
        <hr className="max-w-2xl mx-auto"></hr>
      </div>

      <div className="max-w-xl mx-auto">
        <Accordion index={accordionIndices} onChange={toggleAccordion}>
          {/* NEWA Tools */}
          {Object.entries(newaTools).map(([tool, models], i) => {
            return (
              <AccordionItem key={i}>
                <h3 className="flex justify-between p-5 my-4 text-white rounded-md bg-primary-700">
                  <AccordionButton className="font-bold">
                    <div className="flex items-center">
                      {accordionIndices.includes(i) ? (
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path d="M19 9l-7 7-7-7"></path>
                        </svg>
                      ) : (
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path d="M9 5l7 7-7 7"></path>
                        </svg>
                      )}
                      <span className="ml-2">{tool}</span>
                    </div>
                  </AccordionButton>
                </h3>
                <AccordionPanel className="p-2 outline-none">
                  <h2 className="pb-1 text-sm font-semibold tracking-wide">
                    Switch the tools to 'On' or 'Off' by clicking or tapping the
                    slider button.
                  </h2>
                  <hr className="mt-2" />
                  <div className="grid grid-cols-1 row-gap-4 col-gap-0 mt-8 md:grid-cols-6">
                    <span className="col-start-6 col-end-7 font-bold text-right text-gray-700 uppercase">
                      Display
                    </span>
                    {models
                      .filter(
                        (d) => d.states.includes('ALL') || stateOrProvince,
                      )
                      .map((model, i) => {
                        let m = { ...model }
                        const found = activeTools.find((t) => t.id === model.id)
                        if (found)
                          m = { ...found, name: m.name ? m.name : m.model }
                        return (
                          <AccordionRow
                            key={m.id}
                            i={i}
                            model={m}
                          ></AccordionRow>
                        )
                      })}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </section>
  )
}

import Dashboard from "../components/dashboard"
import Profile from "../components/profile"
import { useAuth0 } from "../context/react-auth0-spa"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"

function UserPage() {
  const { isAuthenticated, loading, user } = useAuth0()

  React.useEffect(() => {
    if (loading) return

    if (!isAuthenticated) {
      navigate("/")
    } else {
      const isFirstVisit = Object.keys(user).length < 4
      if (isFirstVisit) {
        navigate("/user/profile", { state: { tab: 0 } })
      }
    }

    // eslint-disable-next-line
  }, [isAuthenticated, loading])

  return (
    <Router basepath="/user">
      <Profile path="/profile"></Profile>
      <Dashboard path="/"></Dashboard>
    </Router>
  )
}

export default UserPage

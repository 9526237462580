import statesAndProvinces from "../../static/states-and-provinces.json"
import Notification from "../components/notification"
import { useAuth0 } from "../context/react-auth0-spa"
import React from "react"

export default function ProfilePersonalInfo() {
  const { user, setUser } = useAuth0()
  const [name, setName] = React.useState(
    Object.keys(user).includes("name") ? user.name : ""
  )
  const [email, setEmail] = React.useState(
    Object.keys(user).includes("email") ? user.email : ""
  )
  const [stateOrProvince, setStateOrProvince] = React.useState(
    Object.keys(user).includes("stateOrProvince") ? user.stateOrProvince : ""
  )
  // let areFieldsFilled =
  //   name.length !== 0 && email.length !== 0 && stateOrProvince.length !== 0
  //     ? true
  //     : false

  const [isEdit, setIsEdit] = React.useState(
    name.length === 0 || email.length === 0 || stateOrProvince.length === 0
      ? true
      : false
  )
  const [isNotification, setIsNotification] = React.useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    // TODO: Do some validation...
    setUser({ ...user, name, email, stateOrProvince })
    setIsEdit(false)
    // areFieldsFilled = true
    setIsNotification(true)
  }

  function handleCancel() {
    if (
      name.length !== 0 &&
      email.length !== 0 &&
      stateOrProvince.length !== 0
    ) {
      setName(name)
      setEmail(email)
      setStateOrProvince(stateOrProvince)
      setIsEdit(false)
    }
  }

  return (
    <section className="pb-12 mx-4 mt-3 sm:mx-1">
      <h2 className="mb-4 text-2xl font-semibold text-center lg:text-3xl">
        Your Personal Information
      </h2>

      <p className="max-w-sm mx-auto text-center md:text-lg">
        Fill out your information, then click or tap Save. To edit, click or tap
        Edit.
      </p>

      <p className="max-w-sm mx-auto text-sm text-center">
        Next, click or tap on Favorite Stations.
      </p>

      <div className="p-2">
        <hr className="max-w-lg mx-auto"></hr>
      </div>

      <div className="max-w-sm mx-auto mt-8">
        <div className="grid grid-cols-1 gap-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                <sup className="text-red-600">*</sup>Full Name <br />
                <small className="font-light">
                  Use your weather station owner name to create a favorite
                  stations list
                </small>
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                  </svg>
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={`form-input block w-full pl-10 sm:text-sm sm:leading-5
                  ${isEdit && "bg-yellow-50"} border-gray-300`}
                  placeholder="Name"
                  disabled={!isEdit}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-8">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                <sup className="text-red-600">*</sup>Email
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`form-input block w-full pl-10 sm:text-sm sm:leading-5
                  ${isEdit && "bg-yellow-50"} border-gray-300`}
                  placeholder="Email"
                  disabled={!isEdit}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-8">
              <fieldset>
                <label
                  htmlFor="stateOrProvince"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  <sup className="text-red-600">*</sup>State or Province
                </label>
                <div className="mt-1 rounded shadow-sm">
                  <select
                    id="stateOrProvince"
                    aria-label="state or province"
                    required
                    className={`mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 ${
                      isEdit && "bg-yellow-50"
                    } border-gray-300`}
                    disabled={!isEdit}
                    value={stateOrProvince}
                    onChange={(e) => setStateOrProvince(e.target.value)}
                    onBlur={(e) => setStateOrProvince(e.target.value)}
                  >
                    <option value="">Select State or Province</option>
                    {statesAndProvinces.map((state) => (
                      <option key={state.postalCode} value={state.postalCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </fieldset>
            </div>

            {isEdit ? (
              <div className="mt-16">
                <button
                  type="submit"
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150`}
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="inline-flex items-center px-4 py-2 ml-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-gray-600 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <span className="inline-flex mt-8 rounded-md shadow-sm">
                <button
                  onClick={() => setIsEdit(true)}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:border-secondary-700 focus:shadow-outline-secondary active:bg-secondary-700"
                >
                  Edit
                </button>
              </span>
            )}
            <Notification
              isOpen={isNotification}
              setIsNotification={setIsNotification}
              message=""
            ></Notification>
          </form>
        </div>
      </div>
    </section>
  )
}

import GlobalStateContext from "../../context/globalStateContext"
import { modelInSeason } from "../../utils/helpers"
import modelLogic from "./_modelLogic"
import modelData from "./san-jose-scale.json"
import React from "react"

export default function DashboardSummarySanJoseScale({ data }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)
  const isModelInSeason = modelInSeason(dateOfInterest, modelData)

  let mData
  if (data) {
    mData = modelLogic(modelData, data, dateOfInterest, isModelInSeason)
  }

  if (!mData) return null
  return (
    <div className="grid grid-cols-1 px-2 py-3 text-gray-700 rounded sm:grid-cols-2 gap-x-2 bg-gray-50">
      <div className="flex items-center py-2 font-extrabold">
        <span>{mData.dashboardSummary.message}</span>
      </div>
      {isModelInSeason && (
        <div className="flex items-center py-2 sm:justify-end">
          <div>
            {/* From Jan 1 */}
            <div className="py-1">
              <span className="pr-2 font-normal">
                {mData.dashboardSummary.fromJan1.text}
              </span>
              <span className="font-extrabold">
                {mData.dashboardSummary.fromJan1.gdd}
              </span>
            </div>

            {/* Biofix 1 */}
            {Object.keys(mData.dashboardSummary).includes("biofix1") && (
              <div className="py-1">
                <span className="pr-2 font-normal">
                  {mData.dashboardSummary.biofix1.text}
                </span>
                <span className="font-extrabold">
                  {mData.dashboardSummary.biofix1.gdd}
                </span>
              </div>
            )}
            {/* Biofix 2 */}
            {Object.keys(mData.dashboardSummary).includes("biofix2") && (
              <div className="py-1">
                <span className="pr-2 font-normal">
                  {mData.dashboardSummary.biofix2.text}
                </span>
                <span className="font-extrabold">
                  {mData.dashboardSummary.biofix2.gdd}
                </span>
              </div>
            )}

            {/* Biofix 3 */}
            {Object.keys(mData.dashboardSummary).includes("biofix3") && (
              <div className="py-1">
                <span className="pr-2 font-normal">
                  {mData.dashboardSummary.biofix3.text}
                </span>
                <span className="font-extrabold">
                  {mData.dashboardSummary.biofix3.gdd}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

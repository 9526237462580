import React from 'react'
import HashLoader from 'react-spinners/HashLoader'
import otherCropAndIpmTools from '../../static/other-crop-and-ipm-tools.json'
import otherDecisionSupportSystems from '../../static/other-decision-support-systems.json'
import otherWeatherTools from '../../static/other-weather-tools.json'
import Accordion from '../components/accordion'
// Dashboard components ----------------------------------------
import DashboardHeader from '../components/dashboardHeader'
import DashboardStationHeader from '../components/dashboardStationHeader'
import FiveDaysForecast from '../components/fiveDaysForecast'
import Layout from '../components/layout'
import WeatherVariablesModal from '../components/modal'
import NewaWeatherTools from '../components/newaWeatherTools'
import Seo from '../components/seo'
import WeatherStationOverview from '../components/weatherStationOverview'
import { useAuth0 } from '../context/react-auth0-spa'
import useFetchStationData from '../utils/hooks/useFetchStationData'
import useStations from '../utils/hooks/useStations'
import OtherToolsLink from './otherToolLink'

const DashboardPage = React.memo(() => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { user, loading } = useAuth0()
  // console.log(user)
  const { station, setStation, stationList, favoriteStations } = useStations()
  console.log("STATION: ",station)
  const { isLoading, isError, data } = useFetchStationData(station)

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <HashLoader size={48} color={'#1987C2'} loading={loading} />
      </div>
    )
  }

  const h1Text = "My NEWA Dashboard"

  return (
    <Layout station={station} h1Text={h1Text}> 
      <Seo title="My NEWA dashboard" />
      <div className="px-4 mt-12 sm:mx-auto sm:max-w-screen-2xl sm:px-6 lg:px-8">
        <DashboardHeader
          stations={user ? favoriteStations : stationList}
          station={station}
          setStation={setStation}
        ></DashboardHeader>
        {station !== null && (
          <DashboardStationHeader
            station={station}
            data={data}
          ></DashboardStationHeader>
        )}

        {data && station !== null && (
          <div className="grid grid-cols-1 gap-8 my-8 lg:my-12 lg:grid-cols-2 lg:gap-12">
            <WeatherStationOverview
              station={station}
              isLoading={isLoading}
              isError={isError}
              data={data}
              setIsModalOpen={setIsModalOpen}
            ></WeatherStationOverview>

            <div>
              {data && station !== null && (
                <FiveDaysForecast
                  isLoading={isLoading}
                  isError={isError}
                  data={data.dailyData.filter((d) => d.isForecast)}
                ></FiveDaysForecast>
              )}
              <div className="mt-12">
                <NewaWeatherTools station={station}></NewaWeatherTools>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          <div className="flex items-center justify-center my-8 lg:my-12">
            <HashLoader size={48} color={'#1987C2'} loading={isLoading} />
          </div>
        ) : (
          <div className="mt-8 lg:mt-12">
            {/* NEWA Models */}
            {data && station !== null && (
              <div className="mb-8 lg:mb-12">
                <Accordion data={data} station={station}></Accordion>
              </div>
            )}

            <hr className="w-4/5 mx-auto my-12"></hr>

            {/* External Links */}
            <div className="grid grid-cols-1 mb-8 text-center lg:mb-12 lg:grid-flow-col gap-x-6 xl:gap-x-12 lg:grid-cols-12 sm:text-left">
              {/* Other Weather Tools */}
              <OtherToolsLink
                data={otherWeatherTools}
                tool="Other Weather Tools"
              ></OtherToolsLink>

              {/* Other Crop & IPM Tools */}
              <OtherToolsLink
                data={otherCropAndIpmTools}
                tool="Other Crop & IPM Tools"
              ></OtherToolsLink>

              {/* Other Decision Support Systems */}
              <OtherToolsLink
                data={otherDecisionSupportSystems}
                tool="Other Decision Support Systems"
              ></OtherToolsLink>
            </div>
          </div>
        )}

        {isModalOpen && (
          <WeatherVariablesModal
            title="Your Weather Overview"
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            station={station}
          ></WeatherVariablesModal>
        )}
      </div>
    </Layout>
  )
})

export default DashboardPage

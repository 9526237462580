import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs'
import '@reach/tabs/styles.css'
import React from 'react'
import HashLoader from 'react-spinners/HashLoader'
import FavoriteStations from '../components/favoriteStations'
import NewaTools from '../components/newaTools'
import OtherTools from '../components/otherTools'
// tabs
import ProfilePersonalInfo from '../components/profilePersonalInfo'
import { useAuth0 } from '../context/react-auth0-spa'
import Header from './header'
import Subheader from './subHeader'
import Seo from './seo'

const Profile = React.memo(({ location }) => {
  const isTab =
    location &&
    location.state !== null &&
    Object.keys(location.state).includes('tab')
  const { loading, user } = useAuth0()

  const [tabNum, setTabNum] = React.useState(isTab ? location.state.tab : 0)

  let areUserInfo = null
  if (user) {
    areUserInfo = Object.keys(user).length < 5
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <HashLoader size={48} color={'#1987C2'} loading={loading} />
      </div>
    )
  }
  const h1Text = "User settings"

  return (
    <>
      <Header location={location}></Header>
      <Subheader h1Text={h1Text} />
      <Seo title="Profile"></Seo>
      <div className="pb-24">
        <Tabs index={tabNum} onChange={setTabNum}>
          <TabList className="flex justify-center px-2 py-6 md:py-12 md:text-lg">
            <Tab>Personal Info</Tab>
            <Tab
              className={`${
                areUserInfo ? `pointer-events-none opacity-50` : ``
              }`}
            >
              Favorite Stations
            </Tab>
            <Tab
              className={`${
                areUserInfo ? `pointer-events-none opacity-50` : ``
              }`}
            >
              NEWA Tools
            </Tab>
            <Tab
              className={`${
                areUserInfo ? `pointer-events-none opacity-50` : ``
              }`}
            >
              Other Tools
            </Tab>
          </TabList>

          <TabPanels>
            {/* Personal Info */}
            <TabPanel className="outline-none">
              <ProfilePersonalInfo></ProfilePersonalInfo>
            </TabPanel>
            {/* Favorite Stations */}
            <TabPanel className="outline-none">
              <FavoriteStations tabNum={tabNum}></FavoriteStations>
            </TabPanel>
            {/* NEWA Tools */}
            <TabPanel className="outline-none">
              {user && <NewaTools user={user}></NewaTools>}
            </TabPanel>
            {/* Other Tools */}
            <TabPanel className="outline-none">
              {user && <OtherTools user={user}></OtherTools>}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </>
  )
})

export default Profile

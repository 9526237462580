import StationsDropdownV2 from '../components/stationsDropdownV2'
import React from 'react'

export default function DashboardHeader({ stations, station, setStation }) {
  return (
    <header className="justify-between px-4 py-3 mb-12 shadow-lg lg:mb-8 xl:mb-12 bg-gray-50 lg:px-5 md:py-5 md:flex sm:rounded">
      <h1 className="mb-4 text-3xl leading-none text-center md:mb-0 lg:text-4xl md:text-left">
        Dashboard
      </h1>

      <div className="items-center justify-between mb-3 md:mb-0 md:flex">
        <h2 className="flex items-center p-0 mb-2 mr-3 text-base font-body md:p-0 md:mb-0">
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 h-6 text-orange-400 star"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
          My Favorite Stations:
        </h2>

        <div className="w-full sm:w-96">
          <StationsDropdownV2
            options={stations}
            value={station}
            setValue={setStation}
          ></StationsDropdownV2>
        </div>
      </div>
    </header>
  )
}

import {
  gddRangeToMsg,
  calculateGdd,
  baskervilleEmin,
} from "../../utils/helpers"
import { format } from "date-fns"

export default function BlueberryMaggotLogic(
  data,
  dateOfInterest,
  modelData,
  isModelInSeason
) {
  const { elements, dashboardDisplay, base } = modelData
  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    base,
    0,
    baskervilleEmin
  )
  // Determine Management Guide Message ------------------------------------------------
  let selectedDate = dailyDataWithGdd.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = dailyDataWithGdd.find((d) => d.isForecast)
  }

  let managementGuideMsg = null
  if (selectedDate) {
    const msg = gddRangeToMsg(
      elements.managementGuide.thresholds,
      selectedDate.gdd
    )
    if (msg) {
      managementGuideMsg = msg
      managementGuideMsg.explanation = elements.managementGuide.explanation
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////

  // Recalculating degree-days when wild grape bloom ----------------------------------
  const mData = dailyDataWithGdd.map((day) => {
    let avgt = "N/A"
    if (day.maxt !== "M" || day.mint !== "M") {
      avgt = Number(((day.mint + day.maxt) / 2).toFixed(1))
    }

    return {
      date: day.date,
      dateDisplay: format(day.date, "yyyy-MM-dd"),
      ms: day.ms,
      dayOfYear: day.dayOfYear,
      dd: day.dd,
      gdd: day.gdd,
      isForecast: day.isForecast,
      mint: day.mint,
      maxt: day.maxt,
      avgt,
      low: null,
      moderate: null,
      high: null,
    }
  })
  // console.log(mData)
  ////////////////////////////////////////////////////////////////////////////////////

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5
    )
  }
  // console.log(tableData)
  //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  const csvData = mData.map((day) => {
    return {
      Date: day.dateDisplay,
      "Daily DD": day.dd,
      [dashboardDisplay]: day.gdd,
    }
  })
  /////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  const csvDataEnvTable = mData.map((day) => {
    return {
      Date: day.dateDisplay,
      "Avg Temp (˚F)": day.avgt,
      "Max Temp (˚F)": day.maxt,
      "Min Temp (˚F)": day.mint,
    }
  })
  /////////////////////////////////////////////////////////////////////////////////////

  // // Dashboard summary ----------------------------------------------------------------
  let dashboard = { managementGuide: null, fromJan1: "" }

  if (!isModelInSeason) {
    dashboard.managementGuide = elements.managementGuide.outOfSeason
  } else {
    const lastDate = mData.find((d) => d.isForecast)
    if (lastDate) {
      const msg = gddRangeToMsg(
        elements.managementGuide.thresholds,
        lastDate.gdd
      )
      if (msg) {
        dashboard.managementGuide = msg
        dashboard.fromJan1 = lastDate.gdd
      }
    }
  }

  // console.log(dashboard)

  return {
    data: mData,
    managementGuideMsg,
    dashboard,
    selectedDate,
    tableData,
    csvData,
    csvDataEnvTable,
  }
}

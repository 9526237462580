import bbox from '@turf/bbox'
import center from '@turf/center'
import { point } from '@turf/helpers'
import * as React from 'react'
import { useCallback, useState } from 'react'
import MapGL, {
  Layer,
  Marker,
  NavigationControl,
  ScaleControl,
  Source,
} from 'react-map-gl'
import GlobalStateContext from '../context/globalStateContext'
import { useAuth0 } from '../context/react-auth0-spa'
import usePosition from '../utils/hooks/usePosition'
import GeocoderControl from './geocoder-control'

const layerStyle = {
  id: 'newa-stations-profile',
  type: 'symbol',
  source: 'composite',
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      ['case', ['==', ['get', 'isIcao'], true], 'airport', 'newa'],
      22,
      ['case', ['==', ['get', 'isIcao'], true], 'airport', 'newa'],
    ],
    'text-field': ['step', ['zoom'], '', 8, ['to-string', ['get', 'name']]],
    'text-size': 11,
    'text-anchor': 'bottom',
    'text-offset': [0, -1],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': ['case', ['==', ['get', 'isIcao'], false], 1.4, 1],
  },
}

export default function Map({
  station,
  setStation,
  geoJSON,
  favoriteStations,
  tabNum,
}) {
  const { user, setUser } = useAuth0()
  const { position } = usePosition()
  const mapRef = React.useRef()
  const [cursor, setCursor] = useState('auto')
  const { DEFAULT_STATION, counter } = React.useContext(GlobalStateContext)
  const setFavStations = useCallback((station) => {
    if (Object.keys(user).includes('favoriteStations')) {
      const favoriteStations = user.favoriteStations;
      const isStationAlreadyInFavorite = favoriteStations.find(
        (stn) => stn.id === station.id,
      );
      if (!isStationAlreadyInFavorite && station.activeStatus) {
        setUser({ ...user, favoriteStations: [...favoriteStations, station] });
      }
    } else {
      setUser({ ...user, favoriteStations: [station] });
    }
  }, [user, setUser]);
  

  // function setFavStations(station) {
  //   if (Object.keys(user).includes('favoriteStations')) {
  //     const favoriteStations = user.favoriteStations
  //     const isStationAlreadyInFavorite = favoriteStations.find(
  //       (stn) => stn.id === station.id,
  //     )
  //     if (!isStationAlreadyInFavorite && station.activeStatus) {
  //       setUser({ ...user, favoriteStations: [...favoriteStations, station] })
  //     }
  //   } else {
  //     setUser({ ...user, favoriteStations: [station] })
  //   }
  // }

  const onClick = useCallback(
    (event) => {
      const feature = event.features && event.features[0]
      if (feature && feature.source === 'newa-stations-profile') {
        const json = JSON.stringify(feature.properties)
        const stn = JSON.parse(json)
        console.log('Station:',stn)
        if (stn.elems.length !== 0) {
          stn.elems = JSON.parse(stn.elems)
        }
        if (stn.extraelems.length !== 0) {
          stn.extraelems = JSON.parse(stn.extraelems)
        }
        setStation(stn)
        setFavStations(stn)
      }
    },
    [setStation,setFavStations],
  )

  const onMouseEnter = useCallback(() => setCursor('pointer'), [])
  const onMouseLeave = useCallback(() => setCursor('auto'), [])

  React.useEffect(() => {
    if (mapRef.current?.loaded()) {
      mapRef.current?.resize()
    }
    if (!user || favoriteStations.length === 0) return
    const points = favoriteStations.map((stn) => point([stn.lon, stn.lat]))
    const geojson = {
      type: 'FeatureCollection',
      features: points,
    }
    if (favoriteStations.length > 1) {
      const [minLng, minLat, maxLng, maxLat] = bbox(geojson)
      mapRef.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 60, duration: 2000 },
      )
    } else {
      const centerMap = center(geojson)
      mapRef.current?.flyTo({
        center: centerMap.geometry.coordinates,
        zoom: 9,
      })
    }
  }, [favoriteStations, tabNum, user])

  // function handleOnResultGeocoder(e) {
  //   const lat = e.result.center[1]
  //   const lon = e.result.center[0]
  //   const state = fromLatLonToState(lat, lon)
  //   const position = { latitude: lat, longitude: lon }
  //   if (state.isNewaStatePartner) {
  //     // console.log("Is NEWA State")
  //     const closestStns = orderStationsByDistanceFromUser(
  //       position,
  //       allStationsUnformatted,
  //     ).slice(0, 3)
  //     if (closestStns[0].distance < 60) {
  //       // console.log("3 station list")
  //       dispatch({ type: 'inStateSearch', closestStations: closestStns })
  //     } else {
  //       // console.log("first station is more than 60Km away")
  //       const options = {
  //         width: viewport.width,
  //         height: viewport.height,
  //         bounds: state.bbox,
  //         transitionDuration: 1000,
  //       }
  //       const updatedViewport = fitBounds(options)
  //       setViewport(updatedViewport)
  //     }
  //   } else {
  //     // console.log("Is NOT a NEWA State")
  //     const options = {
  //       width: viewport.width,
  //       height: viewport.height,
  //       bounds: NEWA_STATES_BBOX,
  //       padding: 36,
  //     }
  //     const updatedViewport = fitBounds(options)
  //     setViewport({ ...updatedViewport, transitionDuration: 1000 })
  //     dispatch({ type: 'outOfStateSearch' })
  //   }
  // }

  return (
    <MapGL
      id="newaStationsMapProfile"
      ref={mapRef}
      initialViewState={{
        latitude: station ? station.lat : DEFAULT_STATION.lat,
        longitude: station ? station.lon : DEFAULT_STATION.lon,
        zoom: 9,
      }}
      mapStyle={'mapbox://styles/nysipm/cl0gkib5s00g715p2nv3rw5z3'}
      style={{ width: 'auto', height: '100%' }}
      mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
      clickTolerance={3}
      dragRotate={false}
      interactiveLayerIds={[layerStyle.id]}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor={cursor}
    >
      <Source id="newa-stations-profile" type="geojson" data={geoJSON}>
        <Layer {...layerStyle}></Layer>
      </Source>

      <GeocoderControl
        id="geocoderDestinationProfile"
        aria-labelledby="geocoderDestination geocoderSource"
        minLength={4}
        countries="US"
        mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
        position="top-left"
      />

      {/* user geolocation on map */}
      {counter === 0 && position !== null && station !== null && (
        <Marker latitude={position.latitude} longitude={position.longitude}>
          <span className="flex w-3 h-3">
            <span className="absolute inline-flex w-full h-full bg-blue-400 rounded-full opacity-75 animate-ping"></span>
            <span className="relative inline-flex w-3 h-3 bg-blue-500 rounded-full"></span>
          </span>
        </Marker>
      )}

      {favoriteStations.map((stn) => {
        return (
          <Marker
            key={stn.id}
            latitude={stn.lat}
            longitude={stn.lon}
            offset={[0.1, 0]}
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-7 h-7 text-orange-400 star"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          </Marker>
        )
      })}

      {/* Items on Map */}
      <NavigationControl
        position="top-right"
        showCompass={false}
      ></NavigationControl>

      <ScaleControl unit="imperial" position="bottom-right" />
    </MapGL>
  )
}

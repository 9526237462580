import { isGeolocationInUSA } from "../helpers"
import { useState, useEffect } from "react"

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
}

export default function usePosition(watch = false, settings = defaultSettings) {
  // console.log("usePosition called!!!")
  const [position, setPosition] = useState(null)
  const [error, setError] = useState(null)

  const onChange = ({ coords, timestamp }) => {
    if (isGeolocationInUSA(coords.latitude, coords.longitude)) {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        timestamp,
      })
    } else {
      setError("Geolocation is not in USA")
    }
  }

  const onError = (error) => {
    setError(error.message)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const geo = navigator.geolocation
      if (!geo) {
        setError("Geolocation is not supported")
        return
      }

      let watcher = null
      if (watch) {
        watcher = geo.watchPosition(onChange, onError, settings)
      } else {
        geo.getCurrentPosition(onChange, onError, settings)
      }

      return () => watcher && geo.clearWatch(watcher)
    }
  }, [watch, settings])
  // console.log(position, error)
  return { position, error }
}

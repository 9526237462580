import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import React from 'react'
import otherCropAndIpmTools from '../../static/other-crop-and-ipm-tools.json'
import otherDecisionSupportSystems from '../../static/other-decision-support-systems.json'
import otherWeatherTools from '../../static/other-weather-tools.json'
import AccordionRow from '../components/accordionRow'

export default function OtherTools({ user }) {
  const activeTools = Object.keys(user).includes('activeTools')
    ? user.activeTools.filter((t) => t.isVisible)
    : []
  const activeToolsIds = activeTools.map((t) => t.id)

  const stateOrProvince = Object.keys(user).includes('stateOrProvince')
    ? user.stateOrProvince
    : 'NY'

  let defaultIndices = []
  const tools = activeTools.map((d) => d.tool)
  if (tools.includes('Other Weather Tools')) defaultIndices.push(0)
  if (tools.includes('Other Crop & IPM Tools')) defaultIndices.push(1)
  if (tools.includes('Other Decision Support Systems')) defaultIndices.push(2)

  const [accordionIndices, setAccordionIndices] = React.useState(defaultIndices)

  function toggleAccordion(toggledIndex) {
    if (accordionIndices.includes(toggledIndex)) {
      setAccordionIndices(
        accordionIndices.filter(
          (currentIndex) => currentIndex !== toggledIndex,
        ),
      )
    } else {
      setAccordionIndices([...accordionIndices, toggledIndex].sort())
    }
  }

  return (
    <section className="mx-4 mt-3 sm:mx-1">
      <h2 className="text-2xl font-semibold text-center lg:text-3xl">
        Your Other Tools
      </h2>
      <p className="max-w-2xl mx-auto text-center md:text-lg">
        Choose or edit the other tools you want access to. Open each tool box by
        clicking or tapping the arrow. Switch the tool link to 'On' or 'Off' by
        clicking or tapping the slider button. The link will display on your
        Dashboard. Choices and edits are saved immediately.
      </p>
      <p className="max-w-2xl mx-auto text-sm text-center">
        After choosing Other Tools, your Profile is complete. Click or tap on
        Dashboard.
      </p>

      <div className="py-6">
        <hr className="max-w-2xl mx-auto"></hr>
      </div>

      <div className="max-w-xl mx-auto">
        <Accordion index={accordionIndices} onChange={toggleAccordion}>
          {/* NEWA Tools */}
          <AccordionItem>
            <h3 className="flex justify-between p-5 my-4 text-white rounded-md bg-primary-700">
              <AccordionButton className="font-bold">
                <div className="flex items-center">
                  {accordionIndices.includes(0) ? (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M19 9l-7 7-7-7"></path>
                    </svg>
                  ) : (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M9 5l7 7-7 7"></path>
                    </svg>
                  )}
                  <span className="ml-2">Other Weather Tools</span>
                </div>
              </AccordionButton>
            </h3>
            <AccordionPanel className="p-2 outline-none">
              <h2 className="pb-1 text-sm font-semibold tracking-wide">
                Switch the tools to 'On' or 'Off' by clicking or tapping the
                slider button.
              </h2>
              <hr className="mt-2" />
              <div className="grid grid-cols-1 row-gap-4 col-gap-0 mt-8 md:grid-cols-6">
                <span className="col-start-6 col-end-7 font-bold text-right text-gray-700 uppercase">
                  Display
                </span>
                {otherWeatherTools
                  .filter((d) => d.states.includes('ALL') || stateOrProvince)
                  .map((model, i) => {
                    let m = { ...model }
                    m.isVisible = activeToolsIds.includes(m.id) ? true : false
                    return (
                      <AccordionRow key={m.id} i={i} model={m}></AccordionRow>
                    )
                  })}
              </div>
            </AccordionPanel>
          </AccordionItem>

          {/* Other Crop & IPM Tools */}
          <AccordionItem>
            <h3 className="flex justify-between p-5 my-4 text-white rounded-md bg-primary-700">
              <AccordionButton className="font-bold">
                <div className="flex items-center">
                  {accordionIndices.includes(1) ? (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M19 9l-7 7-7-7"></path>
                    </svg>
                  ) : (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M9 5l7 7-7 7"></path>
                    </svg>
                  )}
                  <span className="ml-2">Other Crop & IPM Tools</span>
                </div>
              </AccordionButton>
            </h3>
            <AccordionPanel className="p-2 outline-none">
              <h2 className="pb-1 text-sm font-semibold tracking-wide">
                Switch the tools to 'On' or 'Off' to display or remove the link
                on your dashboard
              </h2>
              <hr className="mt-2" />
              <div className="grid grid-cols-1 row-gap-4 col-gap-0 mt-8 md:grid-cols-6">
                <span className="col-start-6 col-end-7 font-bold text-right text-gray-700 uppercase">
                  Display
                </span>

                {otherCropAndIpmTools
                  .filter((d) => d.states.includes('ALL') || stateOrProvince)
                  .map((model, i) => {
                    let m = { ...model }
                    m.isVisible = activeToolsIds.includes(m.id) ? true : false
                    return (
                      <AccordionRow key={m.id} i={i} model={m}></AccordionRow>
                    )
                  })}
              </div>
            </AccordionPanel>
          </AccordionItem>

          {/* Other Decision Support Systems */}
          <AccordionItem>
            <h3 className="flex justify-between p-5 my-4 text-white rounded-md bg-primary-700">
              <AccordionButton className="font-bold">
                <div className="flex items-center">
                  {accordionIndices.includes(2) ? (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M19 9l-7 7-7-7"></path>
                    </svg>
                  ) : (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M9 5l7 7-7 7"></path>
                    </svg>
                  )}
                  <span className="ml-2">Other Decision Support Systems</span>
                </div>
              </AccordionButton>
            </h3>
            <AccordionPanel className="p-2 outline-none">
              <h2 className="pb-1 text-sm font-semibold tracking-wide">
                Switch the tools to 'On' or 'Off' to display or remove the link
                on your dashboard
              </h2>
              <hr className="mt-2" />
              <div className="grid grid-cols-1 row-gap-4 col-gap-0 mt-8 md:grid-cols-6">
                <span className="col-start-6 col-end-7 font-bold text-right text-gray-700 uppercase">
                  Display
                </span>

                {otherDecisionSupportSystems
                  .filter((d) => d.states.includes('ALL') || stateOrProvince)
                  .map((model, i) => {
                    let m = { ...model }
                    m.isVisible = activeToolsIds.includes(m.id) ? true : false
                    return (
                      <AccordionRow key={m.id} i={i} model={m}></AccordionRow>
                    )
                  })}
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import { Link } from 'gatsby'
import React from 'react'
import { useAuth0 } from '../context/react-auth0-spa'
import AlfalfaWeevil from '../pages/alfalfa-weevil/_dashboardSummary'
import AppleMaggot from '../pages/apple-maggot/_dashboardSummary'
import BeetCercosporaLeafSpot from '../pages/beet-cercospora-leaf-spot/_dashboardSummary'
import BlueberryMaggot from '../pages/blueberry-maggot/_dashboardSummary'
import CabbageMaggot from '../pages/cabbage-maggot/_dashboardSummary'
import CodlingMoth from '../pages/codling-moth/_dashboardSummary'
import GrapeBerryMoth from '../pages/grape-berry-moth/_dashboardSummary'
import ObliquebandedLeafRoller from '../pages/obliquebanded-leafroller/_dashboardSummary'
import OnionMaggot from '../pages/onion-maggot/_dashboardSummary'
import OrientalFruitMoth from '../pages/oriental-fruit-moth/_dashboardSummary'
import PlumCurculio from '../pages/plum-curculio/_dashboardSummary'
import Ptgm from '../pages/pollen-tube-growth-model/_dashboardSummary'
import SanJoseScale from '../pages/san-jose-scale/_dashboardSummary'
import SootyBlotchFlyspeck from '../pages/sooty-blotch-flyspeck/_dashboardSummary'
import SpottedLanternfly from '../pages/spotted-lanternfly/_dashboardSummary'
import SpottedTentiformLeafminer from '../pages/spotted-tentiform-leafminer/_dashboardSummary'

const ExternalLink = ({ model }) => (
  <div className="p-2 text-gray-700 rounded bg-gray-50">
    <a
      href={model.link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center px-2 py-3 font-extrabold cursor-pointer"
    >
      Go to model (external site)
    </a>
  </div>
)

const modelList = [
  { id: 2, comp: <SootyBlotchFlyspeck></SootyBlotchFlyspeck> },
  { id: 4, comp: <AppleMaggot></AppleMaggot> },
  { id: 5, comp: <CodlingMoth></CodlingMoth> },
  {
    id: 6,
    comp: <ObliquebandedLeafRoller></ObliquebandedLeafRoller>,
  },
  { id: 7, comp: <OrientalFruitMoth></OrientalFruitMoth> },
  { id: 8, comp: <PlumCurculio></PlumCurculio> },
  { id: 9, comp: <SanJoseScale></SanJoseScale> },
  {
    id: 10,
    comp: <SpottedTentiformLeafminer></SpottedTentiformLeafminer>,
  },
  { id: 12, comp: <Ptgm></Ptgm> },
  { id: 14, comp: <BlueberryMaggot></BlueberryMaggot> },
  { id: 16, comp: <GrapeBerryMoth></GrapeBerryMoth> },
  { id: 17, comp: <AlfalfaWeevil></AlfalfaWeevil> },
  {
    id: 18,
    comp: <BeetCercosporaLeafSpot></BeetCercosporaLeafSpot>,
  },
  { id: 19, comp: <CabbageMaggot></CabbageMaggot> },
  { id: 21, comp: <OnionMaggot></OnionMaggot> },
  { id: 22, comp: <ExternalLink></ExternalLink> },
  { id: 23, comp: <ExternalLink></ExternalLink> },
  {
    id: 60,
    comp: <SpottedLanternfly></SpottedLanternfly>,
  },
  { id: 61, comp: <ExternalLink></ExternalLink> },
]

export default function NewaAccordion({ data, station }) {
  const { user, setUser } = useAuth0()
  const [activeTools, setActiveTools] = React.useState([])
  const [preExpanded, setPreExpanded] = React.useState([])

  React.useEffect(() => {
    if (!user) return

    if (Object.keys(user).includes('activeTools')) {
      setActiveTools(user.activeTools.filter((t) => t.isVisible))
    }

    if (Object.keys(user).includes('preExpanded')) {
      setPreExpanded(user.preExpanded)
    }
  }, [user])

  function toggleModel(toggledIndex) {
    let arr = []
    if (preExpanded.includes(toggledIndex)) {
      arr = preExpanded.filter((currentIndex) => currentIndex !== toggledIndex)
      setPreExpanded(arr)
    } else {
      arr = [...preExpanded, toggledIndex].sort()
      setPreExpanded(arr)
    }
    setUser({ ...user, preExpanded: arr })
  }

  const models = activeTools
    .filter((t) => t.type === 'NEWA Crop & IPM Tools')
    .map((model) => {
      const found = modelList.find((t) => t.id === model.id)
      return found
        ? { ...model, comp: found.comp }
        : { ...model, comp: <div></div> }
    })
    .sort((a, b) => b.updatedAt - a.updatedAt)

  if (!data) return null

  return (
    <Accordion onChange={toggleModel} index={preExpanded}>
      {models.map((model, i) => {
        return (
          <AccordionItem key={model.id}>
            <h3 className="flex items-center justify-between p-5 my-4 text-white rounded-md bg-primary-700">
              <AccordionButton className="text-base font-bold sm:text-lg">
                <div className="flex items-center">
                  {preExpanded.includes(i) ? (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M19 9l-7 7-7-7"></path>
                    </svg>
                  ) : (
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path d="M9 5l7 7-7 7"></path>
                    </svg>
                  )}

                  <span className="ml-2 text-base sm:text-lg text-left">
                    {model.name}
                  </span>
                </div>
              </AccordionButton>
              {model.id === 22 || model.id === 23 || model.id === 61 ? (
                <a
                  href={model.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-white no-underline border-b border-primary-700 hover:border-b hover:text-white hover:border-white"
                >
                  <span className="text-small lg:text-base">
                    <span className="hidden sm:inline-block">Go To Tool</span>{' '}
                    &rarr;
                  </span>
                </a>
              ) : (
                <Link
                  to={model.link}
                  className="text-sm text-white no-underline border-b border-primary-700 hover:border-b hover:text-white hover:border-white"
                >
                  <span className="text-small lg:text-base">
                    <span className="hidden sm:inline-block">Go To Tool</span>{' '}
                    &rarr;
                  </span>
                </Link>
              )}
            </h3>

            {/* All Models-------------------------------------------------------- */}
            <AccordionPanel className="px-4 pb-2 outline-none">
              {React.cloneElement(model.comp, { data, station, model })}
            </AccordionPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

import Transition from "./transition"
import React from "react"

export default function Notification({ isOpen, setIsNotification, message }) {
  React.useEffect(() => {
    setTimeout(() => {
      setIsNotification(false)
    }, 2000)
  }, [isOpen, setIsNotification])

  return (
    <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <Transition
        show={isOpen}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto">
          <div className="overflow-hidden rounded-lg shadow-xs">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="w-6 h-6 text-green-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium leading-5 text-gray-900">
                    Successfully saved!
                  </p>
                  {message.length !== 0 && (
                    <p className="mt-1 text-sm leading-5 text-gray-500">
                      {message}
                    </p>
                  )}
                </div>
                <div className="flex flex-shrink-0 ml-4">
                  <button
                    onClick={() => setIsNotification(false)}
                    className="inline-flex text-gray-500 transition duration-150 ease-in-out focus:outline-none focus:text-gray-600"
                  >
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

import GlobalStateContext from "../../context/globalStateContext"
import modelLogic from "./_modelLogic"
import React from "react"

export default function DashboardSummaryBeetCercosporaLeafSpot({ data }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  let mData
  if (data) {
    mData = modelLogic(data, dateOfInterest)
  }

  if (!mData) return null
  return (
    <div className="grid grid-cols-1 px-2 py-3 text-gray-700 rounded sm:grid-cols-2 gap-x-2 bg-gray-50">
      <div className="flex items-center py-2 font-extrabold">
        <span>Risk: {mData.dashboardSummary.message}</span>
      </div>
      <div className="flex items-center sm:justify-end">
        <div>
          {/* From Jan 1 */}
          <div className="py-1">
            <span className="pr-2 font-normal">2-Day Infection Value</span>
            <span className="font-extrabold">
              {mData.dashboardSummary.div2Days}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

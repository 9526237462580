import React from 'react'
import Switch from 'react-switch'
import { useAuth0 } from '../context/react-auth0-spa'

export default function AccordionRow({ model, i }) {
  const { user, setUser } = useAuth0()

  function toggleModel(id) {
    let updatedActiveTools = []
    if (Object.keys(user).includes('activeTools')) {
      updatedActiveTools = [...user.activeTools]
    }

    let found = updatedActiveTools.find((t) => t.id === +id)

    if (found) {
      updatedActiveTools = updatedActiveTools.map((t) =>
        t.id === +id
          ? {
              ...t,
              isVisible: !t.isVisible,
              updatedAt: Date.now(),
              name: model.name,
            }
          : t,
      )
    } else {
      updatedActiveTools.push({
        ...model,
        isVisible: true,
        updatedAt: Date.now(),
      })
    }

    setUser({
      ...user,
      activeTools: updatedActiveTools,
    })
  }

  return (
    <>
      <span
        className={`flex items-center col-start-1 col-end-6 p-2 text-base ${
          i % 2 === 0 ? 'white' : 'bg-gray-100 rounded'
        }`}
      >
        <span className={`${model.isVisible ? 'font-bold' : ''}`}>
          {model.name}
        </span>
      </span>
      <label
        htmlFor={model.name || model.model}
        className={`flex items-center justify-end col-start-6 col-end-7 p-2 ${
          i % 2 === 0 ? 'white' : 'bg-gray-100 rounded'
        }`}
      >
        <Switch
          id={model.id.toString()}
          onChange={() => toggleModel(model.id)}
          checked={model.isVisible}
          offColor="#4A5568"
          onColor="#508227"
          uncheckedIcon={
            <div
              className="flex items-center justify-center h-full pr-1 text-xs font-bold text-white"
              aria-hidden="true"
            >
              Off
            </div>
          }
          checkedIcon={
            <div
              className="flex items-center justify-center h-full pl-1 text-xs font-bold text-white"
              aria-hidden="true"
            >
              On
            </div>
          }
          aria-label="switch label to make the current tool visible in the dashboard"
        />
      </label>
    </>
  )
}

import {isAfter} from 'date-fns'
import React from 'react'
import GlobalStateContext from '../../context/globalStateContext'
import useFetchStationData from '../../utils/hooks/useFetchStationData'
import modelData from './spotted-lanternfly.json'
import Graph from './_graph'
import ManagementGuide from './_managementGuide'
import modelLogic from './_modelLogic'

const sept15 = new Date(new Date().getFullYear(), 8, 15)

export default function DashboardSummarySpottedLanternfly({model, station}) {
  const {dateOfInterest} = React.useContext(GlobalStateContext)

  let year = dateOfInterest.year
  let mmm = 8
  let defaultDate = new Date(year, mmm, 15)

  let sdate = ``
  let edate = 'now'
  if (dateOfInterest.isCurrentYear) {
    if (isAfter(new Date(), defaultDate)) {
      sdate = `${year}091500`
    } else {
      defaultDate = new Date(year - 1, mmm, 15)
      sdate = `${year - 1}091500`
    }
  } else {
    defaultDate = new Date(year - 1, mmm, 15)
    sdate = `${year - 1}091500`
    edate = `${year}111500`
  }

  const {isLoading, data} = useFetchStationData(station, sdate, edate)

  const stationId = station?.id
  const currentYear = new Date().getFullYear()
  let lastDate = `${currentYear - 1}-09-15`
  if (isAfter(new Date(), sept15)) {
    lastDate = `${currentYear}-09-15`
  }
  if (model && stationId) {
    if (Object.keys(model).includes('biofix')) {
      let date = ''
      let found = false
      if (currentYear in model.biofix) {
        if (stationId in model.biofix[currentYear]) {
          date = model.biofix[currentYear][stationId]
          found = true
        }
      }

      if (found) {
        const mmDD = date.slice(4)
        if (isAfter(new Date(), sept15)) {
          lastDate = `${currentYear}${mmDD}`
        }
        lastDate = `${currentYear - 1}${mmDD}`
      }
    }
  }

  let mData = null
  if (data) mData = modelLogic(data, lastDate)

  if (isLoading)
    return (
      <div className="px-2 py-3 text-gray-700 rounded bg-gray-50 font-semibold">
        Loading...
      </div>
    )
  if (!mData) return null

  return (
    <>
      <div className="px-2 py-3">
        <Graph
          title="Life Cycle Status"
          data={mData.graphData}
          showBtn={false}
        ></Graph>
      </div>
      <div className="px-2 py-3">
        <ManagementGuide
          data={mData.graphData}
          managementGuide={modelData.elements.managementGuide}
          eggDiapauseDate={mData.eggDiapauseDate}
          eggDevDate={mData.eggDevDate}
          instar1Date={mData.instar1Date}
          instar2Date={mData.instar2Date}
          instar3Date={mData.instar3Date}
          instar4Date={mData.instar4Date}
          completeAdultEmergence={mData.completeAdultEmergence}
        ></ManagementGuide>
      </div>
    </>
  )
}

import newaBadge from '../assets/images/newa_favicon.png'
import { Link } from 'gatsby'
import RadarLogo from '../svg/radar.svg'
// import useFetchRadar from '../utils/hooks/useFetchRadar'
import Card from './card'
import React from 'react'

export default function NewaWeatherTools({ station }) {
  // const { data: radarUrl } = useFetchRadar(station)
  const links = [
    { name: 'Degree Day Calculator', url: '/degree-day-calculator' },
    {
      name: 'Hourly Data',
      url: '/all-weather-data-query',
      fromNewaWeatherTools: { hourly: true },
    },
    {
      name: 'Daily Summary',
      url: '/all-weather-data-query',
      fromNewaWeatherTools: { daily: true },
    },
  ]

  return (
    <Card title="NEWA Weather Tools" color="primary">
      <ul className="grid grid-cols-1 gap-4 px-3 py-4 sm:grid-cols-2">
        {links.map((link) => {
          return (
            <li
              key={link.name}
              className={`text-sm md:text-base py-1 px-2 flex`}
            >
              <img className="w-4 h-4 mr-1" src={newaBadge} alt="Hourly Data" />
              <Link
                to={link.url}
                className="rounded-md"
                state={link.fromNewaWeatherTools}
              >
                {link.name}
              </Link>
            </li>
          )
        })}

        <div className="flex px-2">
          <RadarLogo className="w-4 h-4 mr-1 text-primary-700"></RadarLogo>
          <a
            href={`https://www.weather.gov/`}
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-md"
          >
            Regional Radar
          </a>
        </div>
      </ul>
    </Card>
  )
}
